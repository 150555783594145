<template lang="">
    <div data-cmp="Breadcrumb" class="appc-widget d-flex flex-row">
        <div class="align-items-center d-flex">
            <a @click.prevent="$router.back()" class="btn btn-primary btn-sm font-size-16 --me-2 px-1 rounded-pill flex-shrink-0">
                <span class="material-icons pull-left">
                chevron_left
                </span>
            </a>
        </div>

        <div class="flex-fill flex-shrink-0">
            <nav class="ps-3" style="--bs-breadcrumb-divider: '›';" aria-label="breadcrumb">
                <ol class="breadcrumb mb-0 fw-500 font-size-18 lh-lg">
                    <li class="breadcrumb-item">
                        <router-link class="breadcrumb-item text-decoration-none" :to="$root.links.DASHBOARD">
                            <span class="pe-0">Dashboard</span>
                        </router-link>
                    </li>
                    
                    <li class="breadcrumb-item">
                        <span class="">{{$root.labels.OFFERS}}</span>
                    </li>


                    <!-- <li class="breadcrumb-item" v-if="!planURL">
                        ...
                    </li> -->

                    <li class="breadcrumb-item" v-if="opportunity?.title">
                        {{opportunity.title}} 
                    </li>
                    <li class="breadcrumb-item" v-if="strategy?.title">
                        {{strategy.title}} 
                    </li>
                    <!-- <li class="breadcrumb-item">
                        {{currentForm.title}}
                    </li> -->
                </ol>
            </nav>
        </div>


        <div class="ms-auto">
            <router-link :to="$root.links.LIBRARY_STRATEGIES_AI"
                class="btn btn-sm font-size-16 btn-pill btn-primary me-2 ps-2 pe-3"
                >
                <span class="end-0 material-icons-outlined pull-left">add</span>
                <span class="px-2">Add Content</span>
            </router-link>

            <!-- <router-link to="/library/strategies/sales-pages"
                class="btn btn-sm font-size-16 btn-pill btn-primary me-2 ps-2 pe-3"
                :class="[ /\/library\/strategies\//.test($route.fullPath) ? 'disabled text-muted' : '' ]"
                >
                <span class="end-0 material-icons-outlined pull-left me-1">gamepad</span>
                <span>Strategies</span>
            </router-link> -->

            <!-- <ButtonRecentOpportunities class="d-inline"></ButtonRecentOpportunities> -->
        </div>
    </div>

</template>
<script>

export default {
    name: 'Breadcrump',

    components: {
    },

    data() {
        return {
            current: {
                opportunity: null,
                strategy: null
            }, 
            events: null
        }
    },

    computed:{
        opportunity() {
            return this.current.opportunity || {};
        }, 

        strategy() {
            return this.current.strategy || {};
        }, 

        currentForm() {
            return this.$store.state.current.form || {};
        },

        planURL() {
            let url = null;
            if(this.$store.state.current.opportunity != null) {
                url = `/explore/opportunities/${this.$route.params.opportunity}/plan`;
            }
            return url;
        }
    }, 

    created() {
        this.events = this.$store.getters.eventBus;
        this.events.bind('deeplink', (e, opportunity, strategy, path) => {
            e, opportunity, strategy, path;
            this.current.opportunity = opportunity;
            this.current.strategy = strategy;
        });
    }
    
}
</script>
<style lang="css" scoped>

</style>