<template>
    <div class="cmp-table-base">
        <!-- Table  -->
        <table class="table table-borderless mb-0 d-flex flex-column">
            <!-- Table head -->
            <thead class="border-bottom bg-canvas">
                <tr>
                    <th class="col-name pl-4.5">
                        <div class="text-main fw-500">
                            <span>Name</span>
                            <span class="mx-1">/</span>
                            <span class="text-secondary">Description</span>
                        </div>
                    </th>

                    <th class="col-icons">
                        <div class="settings-icon text-right">
                            <!--
                                TODO: table option buttons (e.g. filter data)
                            -->
                        </div>
                    </th>
                </tr>
            </thead>
            <!-- Table head -->



            <!-- Table body -->
            <tbody class="overflow-scroll tbody d-flex flex-column"  v-if="collection?.length" >

                <!-- {{current.item}} -->

                <tr v-show="collection?.length > 0" 
                    v-for="(item, index) in collection" 
                    :key="'opportunity-' + index" 
                    class="contacts-list table-row d-flex flex-row"
                    :class="[current.item?.name == item?.name? 'active' : '']">

                    <th scope="row" class="pl-4.5 flex-fill">
                        <div class="py-2">
                            <!-- <router-link class="d-flex flex-row table-row-link text-decoration-none" :to="`/edit/opportunities/${item.name}/business/hypothesis`">
                                <div>

                                    <span class="material-icons-outlined me-2 text-secondary">
                                    {{ getIconByKind(item.kind) }}
                                    </span>
                                </div>
                                <div class="table-row-link">
                                    <span class="ml-3 fw-normal d-block h5 item-title">{{item.title}}</span>
                                    <span class="ml-3 fw-normal d-block item-description">{{item.excerpt}}</span>
                                </div>
                            </router-link> -->
                            <a class="d-flex flex-row table-row-link text-decoration-none"
                                @click.prevent="selectIntent(item)"
                                @dblclick.prevent="action('edit', item)"
                                >
                                <div class="item-icon">

                                    <span class="material-icons-outlined me-2 --text-secondary">
                                    {{ getIconByKind(item.kind) }}
                                    </span>
                                </div>
                                <div class="--table-row-link">
                                    <span class="ml-3 fw-normal d-block h5 item-title">{{item.title}}</span>
                                    <span class="ml-3 fw-normal d-block item-description">{{item.excerpt}}</span>
                                </div>
                            </a>
                        </div>
                    </th>



                    <th scope="row" class="--pr-4.5">
                        <div class="edit-icon d-flex flex-column">

                            <!-- <router-link class="bg-white btn btn-pill btn-circle bg-white btn-sm me-2 mt-2"
                                :to="`/edit/strategies/${item.kind}/${item.name}`"
                                >
                                <span class="material-icons-outlined pull-left me-1">
                                edit
                                </span> 
                            </router-link> -->

                            <a class="bg-white btn btn-pill btn-circle bg-white btn-sm me-2 mt-2"
                                @click="action('edit', item)">
                                <span class="material-icons-outlined pull-left me-1">
                                edit
                                </span> 
                            </a>




                            <div class="btn-group border-0 pt-2 me-2" role="group">
                                <!-- <OpportunityDropdownOptions :action="action" :isAdmin="isAdmin" :opportunity="item"></OpportunityDropdownOptions> -->
                                <StrategyDropdownOptions class="strategy-options" :strategy="item" :action="action" :isAdmin="isAdmin"></StrategyDropdownOptions>
                            </div>



                            <!-- <a
                                @click.prevent="edit(item)"
                                class="btn-edit border btn btn-outline-light btn-sm ml-1 px-1 text-decoration-none text-muted"
                                href="#"
                                ><i
                                    class="fa fa-pencil mx-2"
                                ></i
                            ></a>
                            <div class="dropdown d-inline-block">

                                

                                <button class="btn btn-sm btn-outline-light border ml-1 text-decoration-none text-muted dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown">
                                    <i class="fa fa-ellipsis-v" ></i >
                                </button>
                                <div class="dropdown-menu dropdown-menu-right">
                                </div>
                            </div> -->
                        </div>
                    </th>
                </tr>

            </tbody>
            <!-- Table body -->
        </table>
        <!-- Table  -->
        <div v-if="!collection?.length" class="--text-center text-muted fw-normal --h4 py-4 px-4.5 --my-5 ">

                <span>No strategies have been defined for this opportunity. </span> 

            <router-link :to="$root.links.LIBRARY_STRATEGIES_AI" class="">
                <span>Create a new strategy</span> 
            </router-link>     
        </div>
    </div>
</template>

<script>
import StrategyDropdownOptions from "./StrategyDropdownOptions.vue";

export default {
    name: "StrategyTable",

    components: {
        StrategyDropdownOptions
    },
    
    props: {
        collection: {
            type: Array,
            default() {
                return []
            }
        }, 

        select: {
            type: Function,
            default() {
                return function(){

                }
            }
        },

        action: {
            type: Function,
            default() {
                return function(name, data) {
                    // alert(111);
                    name = name + 'Strategy';
                    this.$root.action(name, data);
                }
            }
        }
    },

    data() {
        return {
            current: {
                item: null
            }
        };
    },

    computed: {
        content() {
            // return this.$store.state.opportunities;
            return this.$store.state.strategies || [];
        }, 

        isAdmin() {
            let action = false;
            let user = this.$store.getters.user;
            if(user?.is_admin) {
                action = true;
            }

            return action;
        }
    }, 

    methods: {

		selectIntent(item) {
            this.setCurrentItem(item);
            this.select(item);
		},

        setCurrentItem(item) {
            this.current.item = item;
        },

        getIconByKind(kind) {
            let icon = 'crop_free';
            switch (kind) {
                case 'ads':
                    icon = 'ads_click';
                    break;

                case 'emails':
                case 'cold-emails':
                    icon = 'forward_to_inbox';
                    break;

                case 'pages':
                case 'sales-pages':
                    icon = 'request_page';
                    break;

                case 'ai':
                    icon = 'psychology';
                    break;
            

                case 'others':
                    icon = 'article';
                    break;
            
                default:
                    break;
            }

            return icon;
        }, 
	},

    mounted: function() {
        // this.$store.dispatch('fetchOpportunities');
        // this.$store.dispatch('fetchStrategies');
        // setTimeout(()=>{
        //     if(this.content?.length > 0) {
        //         this.selectIntent(this.content[0]);
        //     }
        // }, 200);

    },
};
</script>

<style scoped>

.active .btn-circle {
    background-color: #fff!important;
    border-width: 0px !important;
}
.cmp-table-base {
    /* font-family: "Roboto", sans-serif; */
    /* height: 100%; */
}
.cmp-table-base .tbody {
    height: calc( 100vh - 268px);
}


.cmp-table-base .table-row .table-row-link .item-title,
.cmp-table-base .table-row .table-row-link .item-description,
.cmp-table-base .table-row .table-row-number,
.cmp-table-base .table-row {
    transition: all .15s;
}

.cmp-table-base .table-row.active  {
    background-color: var(--primary-light) !important;
}
.cmp-table-base .table-row.active .table-row-link .item-title,
.cmp-table-base .table-row.active .table-row-link .item-description {
    color: var(--primary) !important;
}
.cmp-table-base .table-row.active .table-row-number {
    color: #fff !important;
    background-color: var(--primary) !important;
}


.cmp-table-base .table-row:hover:not(.active)  {
    /* cursor: pointer; */
    /* background-color: var(--primary-light) !important; */
}
.cmp-table-base .table-row:hover .table-row-number {
    color: #fff !important;
    background-color: var(--primary) !important;
}

.cmp-table-base .table-row .table-row-link .item-title {
    color: var(--text-main) !important;
}
.cmp-table-base .table-row .table-row-link .item-description {
    color: var(--text-secondary) !important;
}

.cmp-table-base .table-row .table-row-link .item-icon {
    color: var(--text-secondary) !important;
}
.cmp-table-base .table-row .table-row-link:hover .item-icon {
    color: var(--primary) !important;
}
.cmp-table-base .table-row.active .table-row-link .item-icon {
    color: var(--primary) !important;
}



.cmp-table-base .table-row .table-row-link:hover .item-title,
.cmp-table-base .table-row .table-row-link:hover .item-description {
    color: var(--primary) !important;
    cursor: pointer;
}

.cmp-table-base .table-row-link:hover span {
    text-decoration: none !important;
}
.cmp-table-base .table-row-number {
    color: var(--text-main) !important;
    background-color: var(--bg-secondary) !important;
    width: 36px;
    height: 36px;
    line-height: 36px;
}






.cmp-table-base .col-name {
    min-width: 320px;       
}
.cmp-table-base .col-icons {
    min-width: 150px;
}

.cmp-table-base .btn-options {
    min-width: 32px;
    height: 31px;
    text-align: center;
}


.cmp-table-base .labels-name {
    font-size: 10px;
    background: #d4d4d4;
}
.cmp-table-base .edit-icon {
    transition: all 0.2s;
    opacity: 0;
}
.cmp-table-base .contacts-list:hover .edit-icon {
    opacity: 1;
}


.cmp-table-base .btn-first {
    border-top-left-radius: 8px;
    border-bottom-left-radius: 8px;
}

.cmp-table-base .btn-last {
    border-top-right-radius: 8px;
    border-bottom-right-radius: 8px;
}




</style>
