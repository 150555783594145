<template>
    <div class="cmp-table-base">
        <!-- Table  -->
        <table class="table table-borderless mb-0 d-flex flex-column">
            <!-- Table head -->
            <thead class="border-bottom bg-canvas">
                <tr>
                    <th class="col-name pl-4.5">
                        <div class="text-main fw-500">
                            <span>Name</span>
                            <span class="mx-1">/</span>
                            <span class="text-secondary">Description</span>
                        </div>
                    </th>

                    <th class="col-icons">
                        <div class="settings-icon text-right">
                            <!--
                                TODO: table option buttons (e.g. filter data)
                            -->
                        </div>
                    </th>
                </tr>
            </thead>
            <!-- Table head -->



            <!-- Table body -->
            <tbody class="overflow-scroll tbody d-flex flex-column">
                <tr v-show="collection?.length > 0" 
                    v-for="(item, index) in collection" 
                    :key="'opportunity-' + index" 
                    class="contacts-list d-flex flex-row table-row"
                    :class="[current.item?.name == item?.name? 'active' : '']">

                    <th scope="row" class="pl-4.5 flex-fill">
                        <div class="py-2">
                            <!-- <router-link class="d-flex flex-row table-row-link text-decoration-none" :to="`/edit/opportunities/${item.name}/business/hypothesis`">
                                <div>
                                    <div class="table-row-number text-center rounded-circle h4 fw-normal me-3">{{ index + 1}}</div>
                                </div>
                                <div class="table-row-link">
                                    <span class="ml-3 fw-normal d-block h5 item-title">{{item.title}}</span>
                                    <span class="ml-3 fw-normal d-block item-description">{{item.excerpt}}</span>
                                </div>
                            </router-link> -->

                            <a class="d-flex flex-row table-row-link text-decoration-none"
                                @click="selectIntent(item)"
                                @dblclick="action('open', item)"
                                title="Double-click to open"
                                >
                                <div>
                                    <!-- <div class="table-row-number text-center rounded-circle h4 fw-normal me-3">{{ index + 1}}</div> -->
                                    <div class="table-row-number text-center rounded-circle h4 fw-normal me-3 user-select-none">
                                        <span class="material-icons-outlined float-start m-1 row-icon">
                                        done
                                        </span>
                                    </div>
                                </div>
                                <div class="table-row-link flex-fill">
                                    <span class="ml-3 fw-normal d-block h5 item-title">{{item.title}}</span>
                                    <span class="ml-3 fw-normal d-block item-description">{{item.excerpt}}</span>
                                </div>
                            </a>
                        </div>
                    </th>



                    <th scope="row" class="">
                        <div class="edit-icon d-flex flex-column">
                            <router-link class="bg-white btn btn-pill btn-circle bg-white btn-sm me-2 mt-2"
                                :to="`/edit/opportunities/${item.name}/business/overview`"
                                >
                                <span class="material-icons-outlined pull-left me-1" style="margin-left: -2px">
                                edit
                                </span> 
                            </router-link>



                            <div class="btn-group border-0 me-2 pt-2" role="group">
                                <OpportunityDropdownOptions :action="action" :isAdmin="isAdmin" :opportunity="item"></OpportunityDropdownOptions>
                            </div>
                        </div>
                    </th>
                </tr>

            </tbody>
            <!-- Table body -->
        </table>
        <!-- Table  -->
        <div v-show="!collection?.length" class="text-center text-muted fw-normal h4 py-5 my-5">
            Create new business opportunity. 
        </div>
    </div>
</template>

<script>
import OpportunityDropdownOptions from "./OpportunityDropdownOptions.vue";

export default {
    name: "OpportunitiesTable",

    components: {
        OpportunityDropdownOptions
    },

    props: {
        collection: {
            type: Array,
            default() {
                return []
            }
        }, 

        select: {
            type: Function,
            default() {
                return function(){

                }
            }
        },

        action: {
            type: Function,
            default() {
                return function(){

                }
            }
        }
    },

    data() {
        return {
            current: {
                item: null
            }
        };
    },

    computed: { 

        isAdmin() {
            let action = false;
            let user = this.$store.getters.user;
            if(user?.is_admin) {
                action = true;
            }

            return action;
        }
    }, 

    methods: {
		selectIntent(item) {
            this.setCurrentItem(item);
            this.select(item);
		},

        setCurrentItem(item) {
            this.current.item = item;
        },


		// action(name, data) {
        //     name = name + 'Opportunity';
        //     this.$root.action(name, data);
		// },
	},

    mounted: function() {
        // this.$store.dispatch('fetchOpportunities');
    },
};
</script>

<style scoped>
.cmp-table-base {
    /* font-family: "Roboto", sans-serif; */
    /* height: 100%; */
}
.cmp-table-base .tbody {
    height: calc( 100vh - 268px);
}

.cmp-table-base .table-row {
    /* cursor: pointer; */
}

.cmp-table-base .table-row .table-row-link .item-title,
.cmp-table-base .table-row .table-row-link .item-description,
.cmp-table-base .table-row .table-row-number,
.cmp-table-base .table-row {
    transition: all .25s;
}

.cmp-table-base .table-row.active  {
    background-color: var(--primary-light) !important;
    /* border: solid 1px var(--primary-light) !important; */
}
.cmp-table-base .table-row.active .table-row-link .item-title,
.cmp-table-base .table-row.active .table-row-link .item-description {
    color: var(--primary) !important;
}
.cmp-table-base .table-row.active .table-row-number {
    color: #fff !important;
    background-color: var(--primary) !important;
    border: solid 1px var(--primary) !important;
}

.cmp-table-base .table-row.active .table-row-number .row-icon {
    opacity: 1;
}




.cmp-table-base .table-row:hover:not(.active)  {
    /* background-color: var(--primary-light) !important; */
}
.cmp-table-base .table-row:hover .table-row-number {
    /* color: #fff !important;
    background-color: var(--primary) !important; */
}

.cmp-table-base .table-row .table-row-link .item-title {
    color: var(--text-main) !important;
}
.cmp-table-base .table-row .table-row-link .item-description {
    color: var(--text-secondary) !important;
}

.cmp-table-base .table-row .table-row-link:hover .item-title,
.cmp-table-base .table-row .table-row-link:hover .item-description {
    color: var(--primary) !important;
    cursor: pointer;
}

.cmp-table-base .table-row-link:hover span {
    text-decoration: none !important;
}
.cmp-table-base .table-row-number {
    cursor: pointer;
    color: var(--text-main) !important;
    border: solid 1px var(--bg-secondary);
    /* background-color: var(--bg-secondary) !important; */
    width: 32px;
    height: 32px;
    line-height: 32px;
}
.cmp-table-base .table-row-number .row-icon {
    opacity: 0;
    font-size: 22px;
}







.cmp-table-base .col-name {
    min-width: 320px;       
}
.cmp-table-base .col-icons {
    min-width: 150px;
}

.cmp-table-base .btn-options {
    min-width: 32px;
    height: 31px;
    text-align: center;
}


.cmp-table-base .labels-name {
    font-size: 10px;
    background: #d4d4d4;
}
.cmp-table-base .edit-icon {
    transition: all 0.2s;
    opacity: 0;
}
.cmp-table-base .contacts-list:hover .edit-icon {
    opacity: 1;
}


.cmp-table-base .btn-first {
    border-top-left-radius: 8px;
    border-bottom-left-radius: 8px;
}

.cmp-table-base .btn-last {
    border-top-right-radius: 8px;
    border-bottom-right-radius: 8px;
}




</style>
