<template lang="">

    <div class="d-flex flex-column position-fixed top-0 start-0 vh-100 w-100 bg-canvas" data-cmp="BrowseMain">
        <AppHeader></AppHeader>

        <div class="container-fluid border-bottom bg-white position-relative">
            <div class="navbar-wrapper py-navbar bg-white  ps-3 pe-0 py-2.5">
                <Breadcrump></Breadcrump>
            </div>
        </div>

        <div class="container-fluid px-0 min-height ">
            <div class="row-fluid bg-white panels d-flex flex-row">

                <div class="panel-left">
                    <OpportunityList class="h-100"></OpportunityList>
                </div>
                <div class="panel-center">
                    <StrategyList></StrategyList>
                </div>
                <div class="panel-center">
                    <StrategyPreview></StrategyPreview>
                </div>

            </div>
        </div>
    </div>
</template>


<script>

import AppHeader from "@/components/AppHeader";
// import AppFooter from "@/components/AppFooter";
import StrategyList from './StrategyList.vue';
import Breadcrump from './Breadcrump.vue';
import OpportunityList from './OpportunityList.vue';
import StrategyPreview from './StrategyPreview.vue';

// const bootstrap = window.bootstrap;

export default {

    components: {
        AppHeader,
        // AppFooter,
        StrategyList,
        OpportunityList,
        Breadcrump,
        StrategyPreview
    },


    data() {
        return {
            events: null,
            tabs: [
                {
                    title: 'Opportunities',
                    url: 'opportunities',
                    trigger: null
                },
                {
                    title: 'Strategies',
                    url: 'strategies',
                    trigger: null
                }
            ]
        }
    },


    computed: {
        
        libraryURL() {
            let url = this.$root.links.LIBRARY_OPPORTUNITIES;
            if(this.$route.params?.section == 'strategies') {
                url = this.$root.links.LIBRARY_STRATEGIES_AI
            }
            return url;
        }, 

        newButtonAction() {
            // alert('BrowseMain');
            return true;
            // let action = {
            //     label: 'New Opportnity',
            //     callback: this.$root.createOpportunityIntent
            // };
            // if(this.$route.params?.section == 'strategies') {
            //     action.label = 'New Strategy';
            //     action.callback = ()=> {
            //         this.$root.$router.push({path: this.$root.links.LIBRARY_STRATEGIES_AI})
            //     };
            // }

            // return action;
        }
    },

    watch: {
        $route() {
            // let section = this.$route.params?.section;
            // this.tabs.map( (tab)=> {
            //     if(tab.url == section) {
            //         tab.trigger.show();
            //     }
            // });
            setTimeout(()=>{
                // this.deeplink();
            }, 0)
        }
    },

    methods: {
        // initTabs() {
        //     this.tabs.forEach((item)=>{
        //         let tab = document.querySelector('#explore-nav [data-href="#'+ item.url +'"]');
        //             tab.addEventListener('click', (event)=> {
        //                 event.preventDefault();
        //                 this.setTab(item);
        //             });
        //         item.trigger = new bootstrap.Tab(tab);
        //     });
        // }, 

        // setTab(tab) {
        //     tab.trigger.show();
        //     this.$root.$router.push({ path: '/browse/' + tab.url })
        // }



        /**
         * 
         */
        deeplink(opportunity, strategy) {
            let path,
                opportunities = this.$store.state.opportunities || [],
                strategies = this.$store.state.strategies || [];

            if(!opportunity) {
                if(opportunities?.length > 0) {
                    if(!this.$route.params?.opportunity) {
                        opportunity = opportunities[0];
                    } else {
                        opportunities.forEach((item)=> {
                            if(item.name == this.$route.params?.opportunity) {
                                opportunity = item;
                            }
                        });
                    }

                    // if opportunity does not match the one in the URL params.
                    // NOTE: can still be null
                    if(!opportunity) {
                        opportunity = opportunities?.[0] || opportunity;
                    }
                }
            }


            if(!strategy) {
                strategies = strategies.filter( (item) => { return item.opportunity_name == opportunity?.name });

                if(strategies?.length > 0) {
                    if(!this.$route.params?.strategy) {
                        strategy = strategies[0];
                    } else {
                        strategies.forEach((item)=> {
                            if(item.name == this.$route.params?.strategy) {
                                strategy = item;
                            }
                        });
                    }

                    /**
                     * If strategy cannot be found for the current opportunity specifie in URL params
                     * then choose the first one in the group
                     * NOTE: can still be null
                     */
                    if(!strategy) {
                        strategy = strategies[0];
                    }
                }
            }


            if(!opportunity) {
                path = `/browse/opportunities`;
            } else if(opportunity && !strategy) {
                path = `/browse/opportunities/${opportunity.name}`;
            } else if(opportunity && strategy) {
                path = `/browse/opportunities/${opportunity.name}/${strategy.name}`;
            }

            // debugger


            // console.log('DEEPLINK', opportunity, strategy, path);

            // this.$router.push({ path: path });

            this.events.trigger('deeplink', [opportunity, strategy, path]);
        }
    },


    created() {
        this.events = this.$store.getters.eventBus;
        this.events.bind('deeplink', (e, opportunity, strategy, path) => {
            e, opportunity, strategy, path;
            // console.log(`%cDEEPLINK ${path}`, 'color: red', opportunity, strategy, path);
            this.$router.push({ path: path });
        });
    },

    async mounted() {
        await this.$store.dispatch('fetchOpportunities');
        await this.$store.dispatch('fetchStrategies');
        this.deeplink();

        window.browse = this;
    },
    


}

</script>


<style scoped>
.nav-tabs {
    width: 320px;
}

.nav-item {
    width: 33%;
}

.min-height {
    min-height: 50vh;
}

.panels {
    height: calc( 100vh - 148px);
}
.panel-left { width: 36% }
.panel-center { width: 32% }
.panel-right { width: 32% }
/* .nav-link,
.nav-link:hover {
    border: none;
    border-bottom: solid 2px transparent;
    line-height: 38px;
    cursor: pointer;
}
.nav-link.active {
    color: var(--bs-primary) !important;
    border-bottom-color: var(--bs-primary); 
} */
</style>