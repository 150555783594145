<template lang="">

    <div class="">
        <button id="item-options" type="button" class="btn bg-white dropdown-toggle no-carret px-1 py-1 rounded-circle --btn-first --btn-last" data-bs-toggle="dropdown">
            <span class="material-icons-outlined pull-right">
            more_vert
            </span>
        </button>
        <div class="dropdown-menu dropdown-menu-right shadow border-minimal border-radius-8 pt-3">
            <a class="dropdown-item px-4" href="#"
                @click.prevent="action('openModal', opportunity)"
                >
                <span class="material-icons-outlined pull-right">
                open_in_new
                </span>
                Open</a>

            <router-link class="dropdown-item px-4" :to="`/edit/opportunities/${opportunity.name}/business/overview`">
                Edit
            </router-link>

            <a class="dropdown-item px-4" href="#"
                @click.prevent="action('edit', opportunity)"
                >Details</a>

            <a class="dropdown-item px-4" href="#"
                @click.prevent="action('duplicate', opportunity)"
                >Duplicate</a>

            <div class="dropdown-divider"></div>

            <a class="dropdown-item px-4 disabled" href="#"
                @click.prevent="action('share', opportunity)"
                >Share</a>


            <a class="dropdown-item px-4" href="#"
                @click.prevent="action('download', opportunity)"
                >Download</a>


            <!-- Admin options -->
            <span v-if="isAdmin">
                <div class="dropdown-divider"></div>
                <li><h6 class="dropdown-header px-4">ADMIN</h6></li>

                <a class="dropdown-item px-4" href="#"
                    @click.prevent="action('createTemplateFrom', opportunity)"
                    >Create Template</a>
            </span>

            <div class="dropdown-divider"></div>

            <a class="dropdown-item px-4 text-danger" href="#"
                @click.prevent="action('delete', opportunity)"
                >Delete</a>
        </div>
    </div>
</template>
<script>
export default {
    name: "OpportunityDropdownOptions",

    props: {
        action: {
            type: Function 
        }, 
        isAdmin: {
            type: Boolean
        }, 
        opportunity: {
            type: Object
        }
    }
}
</script>
<style lang="">
    
</style>