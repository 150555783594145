<template lang="">
    <div class="table-strategies bg-white border-end h-100">


        <div class="d-flex flex-row pl-4.5 pe-3 pt-4 pb-3">
            <h4 class="flex-fill pt-1 font-size-heading-sm text-main font-weight-normal">{{$root.labels.STRATEGIES}} Content</h4>


            <div class="options">
                <button class="btn btn-outline-secondary btn-pill btn-sm px-1 btn-library me-2"
                    @click.prevent="action('ai-history', null)">
                    <span class="material-icons-outlined pull-left">
                    history
                    </span>
                </button>


                <button class="btn btn-outline-secondary btn-pill btn-sm px-1 btn-library me-2"
                    @click.prevent="action('import', null)">
                    <span class="material-icons-outlined pull-left">
                    edit_note
                    </span>
                </button>


                <button class="btn btn-primary border-primary btn-pill btn-sm px-1"
                    @click.prevent="action('create', null)">
                    <span class="material-icons-outlined pull-left me-1">
                    add
                    </span>
                    <span class="me-2 fw-bold d-inline-block" style="margin-top: 2px">NEW</span>
                </button>
            </div>
        </div>



            <!-- clear filters -->

        <!-- <div class="text-main fw-500 border-bottom py-2 px-4.5">
            <span>Name</span>
            <span class="mx-1">/</span>
            <span class="text-secondary">Description</span>

            <div class="pull-right">
                <span class="fs-6 fw-normal pull-left text-secondary" style="line-height: 28px;">
                    <span v-if="hasFilters">Showing {{selection?.length}} of </span>{{collection?.length}} <span v-if="!hasFilters">strategies</span>
                </span>
                <a @click="clearFilters" class="btn btn-danger btn-sm ps-0 py-0 rounded-pill ms-2" v-if="hasFilters">
                    <span class="material-icons-outlined pull-left me-1">
                    highlight_off
                    </span>

                    <span style="line-height: 24px">
                    CLEAR FILTERS
                    </span>
                </a>
            </div>
        </div> -->

        
        <StrategiesTable :collection="selection" :select="select" :action="action" :isAdmin="isAdmin" ref="table"></StrategiesTable>


        <!-- <div v-if="!collection?.length">
            <div class="alert alert-warning p-5" role="alert">
            You have not defined your strategies yet.
            </div>
        </div> -->
    </div>
</template>
<script>

// import StrategyFilters from './StrategyFilters.vue';
import StrategiesTable from './StrategiesTable.vue';

export default {
    name: 'StrategyList',
    components: {
        // StrategyFilters,
        StrategiesTable
    }, 

    data() {
        return {
            // kindFilter: '',
            // opportunityFilter: '', 

            kinds: [
                { label: 'Ads', name: 'ads', icon: 'ads_click'},
                { label: 'Emails', name: 'cold-emails', icon: 'forward_to_inbox'},
                { label: 'Pages', name: 'sales-pages', icon: 'request_page'},
                { label: 'AI', name: 'ai', icon: 'network_intelligence'}
            ], 

            deeplinked: false,
        }
    },

    computed: {
        kindFilter: {
            get() {
                return this.$store.state.current.kindFilter;
            },

            set(value) {
                this.$store.state.current.kindFilter = value;
            }
        },

        opportunityFilter: {
            get() {
                return this.$store.state.current.opportunityFilter;
            },

            set(value) {
                this.$store.state.current.opportunityFilter = value;
            }
        },

        collection() {
            return this.$store.state.strategies || [];
        }, 

        // opportunities() {
        //     let dictionary = {};
        //     let collection = [];
        //     this.collection.forEach(strategy => {

        //         if(strategy?.opportunity_name?.length > 0 && strategy.opportunity_name in dictionary === false) {
        //             let opportunity = {
        //                 name: strategy.opportunity_name,
        //                 title: strategy.opportunity_title
        //             };
        //             dictionary[strategy.opportunity_name] = true;
        //             collection.push(opportunity);
        //         }
        //     });

        //     return collection;
        // },

        hasFilters() {
            let has = this.kindFilter?.length > 0 || this.opportunityFilter?.length > 0; 
            return has;
        },

        /**
         * Returns a subset of strategies based on the current filters.
         */
        selection() {
            let subset = [];
            
            if(this.$route.params.opportunity?.length > 0) {
                this.collection.forEach(strategy => {
                    if(strategy.opportunity_name == this.$route.params.opportunity) {
                        subset.push(strategy);
                    }
                });
            }

            (function(scope){
                setTimeout(()=>{
                    scope.setCurrentItem();
                }, 0);
            })(this)

            return subset;
        }, 

        isAdmin() {
            let action = false;
            let user = this.$store.getters.user;
            if(user?.is_admin) {
                action = true;
            }

            return action;
        }
    }, 

	watch: {
		'$route' () {
            if(this.$route.params?.strategy?.length) {
                this.setCurrentItem();
            }
		}
	},

    methods: {

        filter(kind, value) {
            this[kind] = value;
        },

        clearFilters() {
            this.kindFilter = '';
            this.opportunityFilter = ''; 
        }, 


        select(strategy) {
            /**
             * It is possible that an opportunity does not have any strategy
             */
            if(!strategy) {
                return;
            }

            /**
             * Allow filtering strategies by opportunity name
             */
            this.opportunityFilter = strategy.name;
            let path = `/browse/opportunities/${this.$route.params?.opportunity}/${strategy.name}`;
            this.$router.push( {path: path} );

            setTimeout(()=>{
                this.$parent.deeplink();
            });

            // console.log(item);
        },


        setCurrentItem() {
            this.selection?.forEach((item)=> {
                if(item.name == this.$route.params?.strategy) {
                    this.$refs["table"]?.setCurrentItem(item);
                }
            });
        },


        action(name, data) {
            // console.log('%cACTION', 'color: green', name, data);

            let url, path = `/edit/strategies/${this.$route.params.opportunity}/${data?.kind}/${data?.name}`;

            switch (name) {
                case 'create':
					this.$root.createStrategyIntent();
                    break;

                case 'open':
					url = document.location.origin + path;
					window.open(url, data.name);
                    break;

                case 'edit':
                    (setTimeout(()=>{
                        this.$router.push({ path: path });
                    }, 0));
                    break;

                case 'details':
                    this.details(data);
                    break;

                case 'duplicate':
                    this.duplicate(data);
                    break;

                case 'import':
                    this.import(data);
                    break;

                case 'share':
                    // 
                    break;

                case 'download':
                    // 
                    break;

                case 'createTemplateFromStrategy':
                    this.createTemplateFromStrategy(data);
                    break;

                case 'delete':
                    this.deleteStrategy(data);
                    break;

                case 'ai-history':
                    this.createStrategyFromHistory();
                    break;

                default:
                    break;
            }
        },



        /**
         * Strategy data
         * 
         * @param {Object} data
         * @param {Object} data.ID
         * @param {Object} data.title
         * @param {Object} data.name
         * @param {Object} data.content
         * @param {Object} data.excerpt
         * @param {Object} data.kind
         * 
         */
        details(data) {
            this.$root.editStrategyDetails(data);
        },


        /**
         * Strategy data
         * 
         * @param {Object} data
         * @param {Object} data.ID
         * @param {Object} data.title
         * @param {Object} data.name
         * @param {Object} data.content
         * @param {Object} data.excerpt
         * @param {Object} data.kind
         * 
         */
        duplicate(data) {
            this.$root.duplicateStrategy(data);
        },

        /**
         * Strategy data
         * 
         * @param {Object} data
         * @param {Object} data.ID
         * @param {Object} data.title
         * @param {Object} data.name
         * @param {Object} data.content
         * @param {Object} data.excerpt
         * @param {Object} data.kind
         * 
         */
        import(data) {
            this.$root.importStrategy(data);
        },





        /**
         * Strategy data
         * 
         * @param {Object} data
         * @param {Object} data.ID
         * @param {Object} data.title
         * @param {Object} data.name
         * @param {Object} data.content
         * @param {Object} data.excerpt
         * @param {Object} data.kind
         * 
         */
        createTemplateFromStrategy(data) {
            this.$root.createTemplateFromStrategy(data);
        },



        createStrategyFromHistory() {
            var $root = this.$root;
            let modal = this.$root.AIShowHistory(function(entry) {

                modal.hide();

                setTimeout(()=>{
                    $root.$root.notify({
                        title: 'Confirm',
                        type: 'confirm',
                        message: 'Would you like to use this entry to create a new strategy?',
                        callback(okay) {
                            okay //&& scope.$router.push( {path: path} );

                            $root.modal({
                                title: 'Create strategy',
                                okay: {
                                    callback: async function(component) {
                                        console.log(entry)
                                        console.log(component)
                                        console.log(component?.data);

                                        // TODO: get rid of suffix
                                        let options = { suffix: 'ai'};

                                        let context = component?.data;

                                        let opportunity = {
                                            opportunity_id: context.opportunity_id,
                                            opportunity_name: context.opportunity_name,
                                            opportunity_title: context.opportunity_title
                                        }

										let request = {
											post_title: (options?.suffix? options.suffix + ': ' + context.title : context.title),
											post_excerpt: context.excerpt,
											post_content: entry.text,
											meta_input: opportunity
										};
										
										$root.$store.commit('SET_LOADING_STATE', true);
										let response = await $root.$store.getters.wp.createAIStrategyForOpportunity(request);
										await $root.$store.dispatch('reloadStrategies');
										$root.$store.commit('SET_LOADING_STATE', false);

										if(response?.ID) {
											$root.openStrategy(response /** strategy data */);
										}


                                    }
                                },
                                // cancel: {callback: ()=> alert(222)},

                            }, 'CreateStrategyContent');
                        }
                    });
                }, 200);
            })
        },



        async deleteStrategy(strategy) {
            await this.$root.deleteStrategy(strategy);
            this.$parent.deeplink();
        },




    },

    mounted() {
        setTimeout(()=>{
            this.setCurrentItem();
        }, 1000);
        // window.strategyList = this;
    },
}
</script>


<style lang="css" scoped>

.table-strategies {
    max-width: 920px;
}


.btn-library {
    color: var(--text-secondary) !important;
    background-color: var(--white) !important;
    border: solid 1px var(--bg-secondary) !important;
}

.btn-library:hover {
    background-color: var(--bg-secondary) !important;
}
.btn-library span {
    line-height: 24px;
}



</style>