<template>
  <div class="cmp-preview">
        <div class="d-flex flex-row px-4 pt-4">
            <h4 class="flex-fill pt-1 font-size-heading-sm text-main font-weight-normal pb-3">Preview</h4>
            <div class="options">
                <button class="btn btn-outline-secondary btn-pill btn-sm px-1 btn-library me-2"
                    @click.prevent="action('reload', null)">
                    <span class="material-icons-outlined pull-left">
                    refresh
                    </span>
                </button>

                <button class="btn btn-outline-primary border-primary btn-pill btn-sm px-1"
                    @click.prevent="action('edit', null)">
                    <span class="material-icons-outlined pull-left px-1">
                    edit
                    </span>
                    <span class="me-2 fw-bold d-inline-block" style="margin-top: 2px">EDIT</span>
                </button>
            </div>
        </div>

        <div class="px-4 pb-2 border-bottom bg-canvas pt-2">
            <div class="text-main fw-500">
                <span v-if="current?.strategy">{{ current.strategy?.title.replace(/^.*\:\s/, '') }}</span>&nbsp;
            </div>
        </div>

        <div class="p-4 preview-content overflow-scroll" v-if="current?.strategy">
            <div v-html="current?.strategy?.content"></div>
        </div>
  </div>
</template>

<script>
export default {
    name: 'StrategyPreview',

    data() {
        return {
            cache: {},
            sessionName: btoa('StrategyPreview'),
            current: {
                strategy: null, 
            }
        }
    },

	watch: {
		'$route' () {
            if(this.current.strategy?.name != this.$route.params?.strategy) {
                this.fetch();
            }
		}
	},
    methods: {
        fetch() {
            // var scope = this;
            if(!this.$route.params?.strategy?.length) {
                this.current.strategy = null;
                return;
            }

            if(this.cache?.[this.$route.params?.strategy] ) {
                this.current.strategy = this.$session.get(this.sessionName)?.[this.$route.params?.strategy];
                return;
            }

            this.current.strategy = null

            setTimeout(async ()=>{
                this.current.strategy = await this.$store.getters.wp.getStrategy(this.$route.params?.strategy);
                this.cache[this.$route.params?.strategy] = this.current.strategy;
                this.$session.set(this.sessionName, this.cache);
            }, 0);
        }, 

        action(name, data) {
            name, data;
            let path, opportunityName = this.current.strategy?.opportunity_name || this.$route.params.opportunity;
            switch (name) {
                case 'edit':
                    path = `/edit/strategies/${opportunityName}/${this.current.strategy.kind}/${this.current.strategy.name}`;
                    this.$router.push({ path: path });
                    break;
            
                case 'reload':
                    this.cache = {};
                    this.$session.set(this.sessionName, this.cache);
                    this.fetch();
                    break;
            
                default:
                    break;
            }
        }
    },

    mounted() {
        this.cache = this.$session.get(this.sessionName) || {};
        this.$route.params?.strategy && this.fetch();
        window.preview = this;
    }
}
</script>

<style >

.cmp-preview .btn-library {
    color: var(--text-secondary) !important;
    background-color: var(--white) !important;
    border: solid 1px var(--bg-secondary) !important;
}

.cmp-preview .preview-content h1, 
.cmp-preview .preview-content h2, 
.cmp-preview .preview-content h3, 
.cmp-preview .preview-content h4, 
.cmp-preview .preview-content h5, 
.cmp-preview .preview-content ul, 
.cmp-preview .preview-content ol, 
.cmp-preview .preview-content li, 
.cmp-preview .preview-content pre, 
.cmp-preview .preview-content p {
    margin-bottom: 0.8rem;
}

.cmp-preview .preview-content h1 { font-size: 2em; font-weight: 400}
.cmp-preview .preview-content h2 { font-size: 1.5em; font-weight: 400 }
.cmp-preview .preview-content h3 { font-size: 1.4em; font-weight: 400 }
.cmp-preview .preview-content h4 { font-size: 1em; }
.cmp-preview .preview-content h5 { font-size: 0.83em; }

.cmp-preview .preview-content {
    height: calc( 100vh - 268px);
}

.btn-library {
    color: var(--text-secondary) !important;
    background-color: var(--white) !important;
    border: solid 1px var(--bg-secondary) !important;
}

.btn-library:hover {
    background-color: var(--bg-secondary) !important;
}
.btn-library span {
    line-height: 24px;
}


</style>