<template lang="">
    <div class="">
        <button id="item-options" type="button" class="btn bg-white dropdown-toggle no-carret px-1 py-1 rounded-circle" data-bs-toggle="dropdown">
            <span class="material-icons-outlined pull-right">
            more_vert
            </span>
        </button>
        <div class="dropdown-menu dropdown-menu-right shadow border-minimal border-radius-8 pt-3">

            <a class="dropdown-item px-4 py-2"
                @click.prevent="action('open', strategy)"
                >
                <span class="material-icons-outlined pull-right">
                open_in_new
                </span>
                Open
            </a>

            <!-- <router-link :to="`/edit/strategies/${strategy.kind}/${strategy.name}`" class="dropdown-item px-4">
                Edit
            </router-link> -->
            <a class="dropdown-item px-4 py-2" href="#"
                @click.prevent="action('edit', strategy)"
                >
                Edit
            </a>


            <a class="dropdown-item px-4 py-2" href="#"
                @click.prevent="action('details', strategy)"
                >
                Details
            </a>

            <a class="dropdown-item px-4 py-2" href="#"
                @click.prevent="action('duplicate', strategy)"
                >
                Duplicate
            </a>

            <a class="dropdown-item px-4 py-2 disabled" href="#"
                @click.prevent="action('import', strategy)"
                >
                Import
            </a>

            <div class="dropdown-divider"></div>

            <a class="dropdown-item px-4 py-2 disabled" href="#"
                @click.prevent="action('share', strategy)"
                >
                Share
            </a>


            <a class="dropdown-item px-4 py-2 disabled" href="#"
                @click.prevent="action('download', strategy)"
                >
                Download
            </a>
            

            <span v-if="isAdmin">
                <div class="dropdown-divider"></div>
                <li><h6 class="dropdown-header px-4">ADMIN</h6></li>

                <a class="dropdown-item px-4 py-2" href="#"
                    @click.prevent="action('createTemplateFromStrategy', strategy)"
                    >
                    Create Template
                </a>
            </span>

            <div class="dropdown-divider"></div>

            <a class="dropdown-item px-4 py-2 text-danger" href="#"
                @click.prevent="action('delete', strategy)"
                >Delete</a>
        </div>
    </div>
</template>


<script>
export default {
    
    props: {
        strategy: {
            type: Object,
            default() {
                return {}
            }
        },

        isAdmin: {
            type: Boolean,
            default() {
                return false
            }
        },

        action: {
            type: Function,
            default() {
                return function(){}
            }
        }
    }, 
}
</script>
<style lang="">
    
</style>